*::-webkit-scrollbar-track {
  /* -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3); */
  border-radius: 10px;
  background-color: transparent;
}

*::-webkit-scrollbar {
  width: 10px;
  height: 10px;
  background-color: transparent;
  cursor: pointer;
}

*::-webkit-scrollbar-thumb {
  border-radius: 12px;
  /* -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3); */
  background-color: #aaabab;
  cursor: pointer;
}
