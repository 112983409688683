@tailwind base;
@tailwind components;
@tailwind utilities;

@layer utilities {
  /* Scroll thumb styles /
  .scrollbar::-webkit-scrollbar {
    width: 0.5rem;
  }

  .scrollbar::-webkit-scrollbar-thumb {
    background: #27272e;
    border-radius: 1rem;
  }

  .scrollbar::-webkit-scrollbar-thumb:active {
    background-color: #131314;
  }

  .scrollbar::-webkit-scrollbar-thumb:hover {
    background: #3b3b46;
    box-shadow: 0 0 2px 1px rgba(0, 0, 0, 0.2);
  }

  / Scroll track styles (Background) */
  .scrollbar::-webkit-scrollbar-track {
    background: #131314;
    border-radius: 4px;
  }

  .scrollbar::-webkit-scrollbar-track:hover,
  .scrollbar::-webkit-scrollbar-track:active {
    background: #131314;
  }
}

.react_time_range__time_range_container {
  padding: 0 !important;
  width: 100% !important;
  margin-top: 2.5em;
}
